<script setup lang="ts">
import { DsNavigation, DS_ICONS, DsIcon, DsButton, useDsToast, DsSelect } from '@dreamshaper/ds-ui'
import { storeToRefs } from 'pinia'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useReportStore } from '@/stores/reportStore'
import SelectLanguage from '@/components/SelectLanguage/SelectLanguage.vue'

type LayoutProps = {
  classContainer?: string | Record<string, boolean>
}

const { t } = useI18n()

useHead({
  htmlAttrs: {
    class: 'light',
  },
  title: t('report.title'),
})

const props = defineProps<LayoutProps>()

const toasts = useDsToast()
const { report, reportView } = storeToRefs(useReportStore())
const { goToReportPage } = useReportStore()

const structure = computed<any[]>(() => {
  const optionsStructure = []

  optionsStructure.push({
    label: t('report.sidebar.general'),
    childs: [],
    component: 'p',
    componentProps: {
      onClick: () => goToReportPage('general'),
    },
  })

  optionsStructure.push({
    label: t('report.sidebar.classes'),
    childs: [],
    component: 'p',
    componentProps: {
      onClick: () => goToReportPage('class'),
    },
  })

  optionsStructure.push({
    label: t('report.sidebar.projects'),
    childs: [],
    component: 'p',
    componentProps: {
      onClick: () => goToReportPage('project'),
    },
  })

  optionsStructure.push({
    label: t('report.sidebar.students'),
    childs: [],
    component: 'p',
    componentProps: {
      onClick: () => goToReportPage('student'),
    },
  })

  optionsStructure.push({
    label: t('report.sidebar.teachers'),
    childs: [],
    component: 'p',
    componentProps: {
      onClick: () => goToReportPage('teacher'),
    },
  })

  return optionsStructure
})

const originalView = ref(reportView.value)

const captureContent = (selector: string) => {
  return new Promise<string>((resolve) => {
    const content = document.querySelector(selector) as HTMLElement
    const options = {
      backgroundColor: null,
      scale: 2,
    }

    html2canvas(content, options).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      resolve(imgData)
    })
  })
}

const pdf = new jsPDF('p', 'mm', 'a4')
const pdfWidth = pdf.internal.pageSize.getWidth()
let currentPage = 1

const addContentToPDF = async (view: any, title: string) => {
  reportView.value = view
  await nextTick()
  const imgData = await captureContent(`#content-to-print`)
  const imgProps = pdf.getImageProperties(imgData)
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

  if (currentPage > 1) {
    pdf.addPage()
  }

  pdf.text(title, 10, 10)
  pdf.addImage(imgData, 'PNG', 0, 20, pdfWidth, pdfHeight)
  currentPage++
}

const _generatePdf = async () => {
  await addContentToPDF('general', t('report.pdf.general'))
  await addContentToPDF('class', t('report.pdf.class'))
  await addContentToPDF('project', t('report.pdf.project'))
  await addContentToPDF('teacher', t('report.pdf.teacher'))
  await addContentToPDF('student', t('report.pdf.student'))

  reportView.value = originalView.value

  pdf.save('report.pdf')
}

const copyLink = () => {
  const url = window.location.href
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toasts?.add({
        detail: t('report.toast.copy_link.success.detail'),
        severity: 'info',
        closable: true,
        duration: 5000,
        position: 'top-right',
      })
    })
    .catch((_err) => {
      toasts?.add({
        summary: t('report.toast.copy_link.error.summary'),
        detail: t('report.toast.copy_link.error.detail'),
        severity: 'error',
        closable: true,
        duration: 5000,
        position: 'top-right',
      })
    })
}
</script>

<template>
  <header class="report-fixed-header">
    <div class="report-fixed-header-content">
      <div class="flex items-center gap-4">
        <DsIcon
          type="svg"
          :icon="DS_ICONS.DreamShaperDS"
          fill="var(--ds-main-bg-color)"
          :height="30"
          :width="30"
        />

        <h5>{{ `${report?.customer?.name ? `${report.customer.name}  - ` : ''}${report?.name || ''}` }}</h5>
      </div>

      <div class="flex items-center gap-2">
        <!-- <DsButton
          color="primary"
          @click="generatePdf"
        >
          Generate PDF
        </DsButton> -->

        <SelectLanguage />

        <DsButton
          color="primary"
          @click="copyLink"
        >
          {{ t('report.button.action.copy_link') }}
        </DsButton>
      </div>
    </div>
  </header>

  <main style="background-color: #e9eef3">
    <section class="reporting-layout flex min-h-screen h-full">
      <DsNavigation
        name="DS Reporting"
        type="report"
        :header-icon="DS_ICONS.DreamShaperDS"
        :show-env-name="false"
        :structure="structure"
      />

      <section class="flex flex-col w-full ml-[12.5rem]">
        <section class="flex flex-col w-full h-full items-center gap-14">
          <Container :class="props.classContainer">
            <slot name="container" />
          </Container>
        </section>
      </section>
    </section>
  </main>
</template>

<style lang="scss">
.report-fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0.625rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 3.75rem;
}

.report-fixed-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.reporting-layout {
  .ds-navigation {
    .sidebar {
      &.collapsed {
        nav {
          padding-top: 8rem !important;
        }
      }
      .header > div {
        .ds-icon > svg {
          width: 1.875rem;
          height: 1.875rem;
        }
      }
    }
  }

  .toggle-button {
    padding-right: 8px;

    .toggle-button-is-collapsed {
      padding-right: 0px
    }
  }
}
</style>
