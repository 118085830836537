<script setup lang="ts">
import { DS_ICONS, DsIcon } from '@dreamshaper/ds-ui'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

import BrazilSvg from '@/assets/icons/brazil.svg'
import ColombiaSvg from '@/assets/icons/colombia.svg'
import FranceSvg from '@/assets/icons/france.svg'
import HungarySvg from '@/assets/icons/hungary.svg'
import PortugalSvg from '@/assets/icons/portugal.svg'
import RomaniaSvg from '@/assets/icons/romania.svg'
import SpainSvg from '@/assets/icons/spain.svg'
import USASvg from '@/assets/icons/united-states.svg'

type LanguageType = {
  iconSrc: string
  altText: string
  label: string
  value: string
}

const { locale, t } = useI18n()

const languages = computed<LanguageType[]>(() => [
  {
    iconSrc: SpainSvg,
    altText: 'ca_ES',
    label: t('language.ca_ES'),
    value: 'ca-ES',
  },
  {
    iconSrc: USASvg,
    altText: 'en_US',
    label: t('language.en_US'),
    value: 'en-US',
  },
  {
    iconSrc: ColombiaSvg,
    altText: 'es_CO',
    label: t('language.es_CO'),
    value: 'es-CO',
  },
  {
    iconSrc: SpainSvg,
    altText: 'es_ES',
    label: t('language.es_ES'),
    value: 'es-ES',
  },
  {
    iconSrc: FranceSvg,
    altText: 'fr_FR',
    label: t('language.fr_FR'),
    value: 'fr-FR',
  },
  {
    iconSrc: HungarySvg,
    altText: 'hu_HU',
    label: t('language.hu_HU'),
    value: 'hu-HU',
  },
  {
    iconSrc: BrazilSvg,
    altText: 'pt_BR',
    label: t('language.pt_BR'),
    value: 'pt-BR',
  },
  {
    iconSrc: PortugalSvg,
    altText: 'pt_PT',
    label: t('language.pt_PT'),
    value: 'pt-PT',
  },
  {
    iconSrc: RomaniaSvg,
    altText: 'ro_RO',
    label: t('language.ro_RO'),
    value: 'ro-RO',
  },
])

const getLanguage = (languageValue?: string): LanguageType => {
  return (
    languages.value.find(lang => lang.value === languageValue)
    || languages.value[0]
  )
}

const isOpen = ref(false)
const selectRef = ref<HTMLDivElement | null>(null)
const selectedLanguage = ref(locale.value)
const currentLanguage = ref<LanguageType>(getLanguage(selectedLanguage.value))

onClickOutside(selectRef, () => {
  isOpen.value = false
})

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const setLanguage = (language: string) => {
  locale.value = language
  localStorage.setItem('selectedLanguage', language)
}

const selectLanguage = (language: LanguageType) => {
  currentLanguage.value = language
  setLanguage(language.value)
  isOpen.value = false
}

watch(locale, (newLocale, oldLocale) => {
  if (!newLocale) return

  if (oldLocale && oldLocale !== newLocale) {
    currentLanguage.value = getLanguage(newLocale)
  }
})

onMounted(() => {
  const savedLanguage = localStorage.getItem('selectedLanguage')

  if (savedLanguage) {
    currentLanguage.value = getLanguage(savedLanguage)
    locale.value = savedLanguage
  } else {
    const browserLanguage = navigator.language || navigator.languages[0]

    const matchingLanguage = languages.value.find(lang =>
      lang.value.startsWith(browserLanguage.split('-')[0]),
    )
    if (matchingLanguage) {
      currentLanguage.value = matchingLanguage
      locale.value = matchingLanguage.value
    }
  }
})
</script>

<template>
  <div
    ref="selectRef"
    class="select-language-container"
  >
    <div
      class="select-language-section-label"
      @click="toggleDropdown"
    >
      <div class="language-label">
        <img
          :src="currentLanguage.iconSrc"
          :alt="currentLanguage.altText"
          class="flag-icon"
          loading="lazy"
        >

        <span>{{ currentLanguage.label }}</span>
      </div>

      <DsIcon
        :icon="DS_ICONS.ArrowDownWhite"
        type="svg"
        :class="{ 'rotate-90': isOpen }"
      />
    </div>

    <div
      v-if="isOpen"
      class="select-options"
    >
      <div
        v-for="language in languages"
        :key="language.value"
        class="select-option"
        @click="selectLanguage(language)"
      >
        <img
          :src="language.iconSrc"
          :alt="language.altText"
          class="flag-icon"
          loading="lazy"
        >

        <span>{{ language.label }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url("./SelectLanguage.scss");
</style>
